import React, { useState } from 'react';

import { COLORS } from 'const';

interface HnapAppContextInterface {
  isLoading: boolean;
  backgroundColor: string;
  headerContent: string | React.ReactNode;
  canInitilizeGA: boolean;
  setLoading: (isLoading: boolean) => void;
  setBackgroundColor: (color: string) => void;
  setHeaderContent: (conent: string | React.ReactNode) => void;
  setCanInitilizeGA: (canInitilizeGA: boolean) => void;
}

const HnapAppContext = React.createContext<HnapAppContextInterface>(
  {} as HnapAppContextInterface
);

interface HnapAppProviderProps {
  children: React.ReactNode;
}

export const HnapAppProvider = ({
  children,
}: HnapAppProviderProps): JSX.Element => {
  const [isLoading, setLoading] = useState(false);
  const [canInitilizeGA, setCanInitilizeGA] = useState(false);
  const [backgroundColor, setBackgroundColor] = useState(COLORS.WHITE);
  const [headerContent, setHeaderContent] = useState<string | React.ReactNode>(
    'HINO CONNECT+'
  );

  const contextState: HnapAppContextInterface = {
    isLoading,
    backgroundColor,
    headerContent,
    canInitilizeGA,
    setLoading,
    setBackgroundColor,
    setHeaderContent,
    setCanInitilizeGA,
  };

  return (
    <HnapAppContext.Provider value={contextState}>
      {children}
    </HnapAppContext.Provider>
  );
};

export default HnapAppContext;
