import React, { useState, useContext, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { COLORS } from 'const';
import { MENUS } from 'hnap/utils/commonParts';
import { PATH, PAGE_TITLES } from 'hnap/hnapConst';
import Header from 'hnap/views/components/Header/Header';
import Footer from 'hnap/views/components/Footer/Footer';
import SideMenu from 'hnap/views/components/SideMenu/SideMenu';
import HnapAppContext from 'hnap/utils/store/hnapAppContext';

import styles from './PlainTemplate.module.scss';

interface PageTemplateProps {
  wide?: boolean;
  className?: string;
  children: React.ReactNode;
}

const PageTemplate = ({
  wide,
  className,
  children,
}: PageTemplateProps): JSX.Element => {
  const location = useLocation();
  const [showMenu, setShowMenu] = useState(false);
  const { backgroundColor } = useContext(HnapAppContext);

  const showMenuHandler = (show: boolean) => setShowMenu(show);

  useEffect(() => {
    const pathIndex = Object.values(PATH.HNAP)
      .splice(1)
      .findIndex((path) => location.pathname.includes(path));
    document.title = PAGE_TITLES[pathIndex] ?? 'HINO CONNECT+';
  }, [location]);

  return (
    <div
      className={`${styles.root} ${
        wide ? styles['root--wide'] : ''
      } ${className}`}
      style={{
        background: `var(--${backgroundColor || COLORS.WHITE})`,
      }}
    >
      <Header
        className={styles.header}
        showMenu={showMenu}
        onShowMenu={showMenuHandler}
      />
      <main className={styles.main}>{children}</main>
      <div
        className={`${styles.mask} ${showMenu ? styles.mask__show : ''}`}
        onClick={() => setShowMenu(false)}
        aria-hidden='true'
      />
      <SideMenu
        menus={MENUS}
        className={`${styles.menu} ${showMenu ? styles.menu__show : ''}`}
        onShowMenu={showMenuHandler}
      />
      <Footer className={styles.footer} />
    </div>
  );
};

export default PageTemplate;
