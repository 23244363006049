/**
 *  定数値ファイル(HINO CONNECT+用)
 *
 */

/**
 * サービスID定数値
 */
export const SERVICE_ID = {
  // 共通
  COMMON_SERVICE_ID: '0000',
};

/**
 * ドキュメント表示ストレージID定数値
 */
export const STRAGE_ID = {
  MANUAL_PDF: '0001',
  TERMS_OF_USE_PDF: '0002',
  HINO_APP_HOW_TO_USE_PDF: '0003',
  PRIVACY_POLICY_PDF: '0004',
  INQUIRY_PDF: '0006',
  FAQ_PDF: '0007',
};

/**
 * 規約タイプ定数値
 */
export const TERMS_TYPE = {
  // 利用規約
  TERMS_OF_USE: '0000',
};

/**
 * アプリメニュー用定数値
 */
export const MENU = {
  VERSION: 'バージョン',
  TITLE: {
    TOP: 'TOP',
    HINO_APP_HOW_TO_USE: 'HINO CONNECT+の使い方',
    TERMS_OF_USE: '利用規約',
    PRIVACY_POLICY: 'プライバシーポリシー',
    INQUIRY: 'お問い合わせ',
    FAQ: 'FAQ',
    USER_MANAGEMENT: 'ユーザー管理',
    LOGOUT: 'ログアウト',
  },
};

export const COLORS = {
  DANGER: 'danger',
  INVALID: 'invalid',
  PRIMARY: 'primary',
  BLACK: 'black',
  WHITE: 'white',
  GRAY: 'gray',
  DARK_GRAY: 'dark-gray',
};

export const PATH = {
  HNAP: {
    ROOT: '/hnap/view',
    TERM: '/term',
    SERVICE_LIST: '/service-list',
    USER_LIST: '/user/list',
    USER_REGIST: '/user/regist',
    USER_UPDATE: '/user/update',
  },
  SEAP: '/seap',
  API: {
    TERMS_VERSION: '/hnap/api/termsversion',
    SERVICE_ID: '/hnap/api/serviceid',
    TERMS_INFO: '/hnap/api/termsinfo',
    LOGOUT: '/hnap/api/logout',
    USER_LIST: '/hnap-bs/api/user/get',
    USER_UPDATE_INFO: '/hnap-bs/api/user/updateinfo',
    USER_REGIST_INFO: '/hnap-bs/api/user/registinfo',
    REGIST_USER: '/hnap-bs/api/user/regist',
    UPDATE_USER: '/hnap-bs/api/user/update',
    DELETE_USER: '/hnap-bs/api/user/delete',
    DOC: '/hnap/api/doc/',
  },
  ERROR: '/hnap/view/err/index.html',
};

export const LABEL = {
  COMPANY_NAME: '企業名',
  OFFICE_NAME: '事業所名',
  EMAIL: 'メールアドレス',
  NAME: '氏名',
  KANA_NAME: '氏名（カナ）',
  ACCOUNT_NAME: 'ニックネーム',
};

export const VALIDATE_MESSAGE = {
  REQUIRED: '{0}は入力必須項目です。',
  MAX_LENGTH: '{0}は{1}文字以下で入力してください。',
  INVALID_MAIL_ADDRESS: 'メールアドレスを正しく入力してください。',
  NO_USER_SELECTED:
    'ユーザーが選択されていません。\n削除選択チェックボックスにチェックを入れていただき、再度削除を実行してください。',
};

export const FOOTNOTE = {
  EMAIL_REGIST:
    '入力したメールアドレス宛に初期パスワードが通知されます。\n入力したメールアドレスにお間違いがないかご確認ください。',
  EMAIL_UPDATE:
    'メールアドレスを変更した場合、新しいメールアドレス宛に初期パスワードが通知されます。また、元のメールアドレスによるログインが利用できなくなります。',
  EMAIL_UPDATE_CAUTION:
    '※メールアドレスを変更するユーザーがログアウトしていることを確認してください。\n※ご自身のメールアドレスを変更する場合、変更後に自動的にログアウトします。',
};

export const PAGE_TITLES = [
  '共通規約同意　HINO CONNECT+',
  'サービス一覧　HINO CONNECT+',
  'ユーザー一覧　HINO CONNECT+',
  'ユーザー登録　HINO CONNECT+',
  'ユーザー更新　HINO CONNECT+',
];
